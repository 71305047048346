import { useEffect, useState, useContext } from "react";
import DashboardHeader from "../dashboardHeader/dashboardHeader";
import { UserContext } from "../../contexts/userContext";
import CardItem from "../cardItem/cardItem";
import DarkCardItem from "../cardItem/darkCardItem";
import { WatchedSearchesIcon } from "../../images/svg/watched_searches_icon";
import { HistoryIcon } from "../../images/svg/history_icon";
import { DefaultAvatar } from "../../images/svg/default-avatar-invite";
import { FingerPrintLogo } from "../../images/svg/fingerPrintLogo";
import DashboardFooter from "./dashboardFooter";
import { useNavigate, useLocation } from "react-router-dom";
import SavedSearchesWithQuery from "../savedSearchesWithQuery/savedSearchesWithQuery";
import {
  ME,
  GET_SAVED_SEARCHES,
  UPDATE_FERRET_USER,
  UPDATE_DEVICE_TOKEN,
} from '../../queries/queries';
import { useLazyQuery } from 'react-apollo';
import NotificationListView from "./notificationListView";
import CompleteTooltip from "../onboardingTooltips/completeTooltip/completeTooltip";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import SearchLimitExceedModal from '../searchLimitExceedModal';
import { ModelType } from '../searchLimitExceedModal/searchLimitExceedModal';

import NotificationTooltip from "../onboardingTooltips/notificationTooltip";
import {
  OnboardingContext,
  OnboardingStates,
  getNextOnboardingStatus,
} from '../../contexts/onboardingContext';
import { setUserInfo, userInfo } from '../../root-navigation';
import Variables from "../../utils/Variables";
import { useMutation } from '@apollo/react-hooks';
import ImportContactsBanner from "./importContactsBanner";
import { getDeviceDetails } from "../../utils/DeviceDetails";
import PushNotificationStatus from "../pushNotification/PushNotificationStatus";
import { dashboardStore } from '../../store/dashboardStore'
import LaunchScreenTransition from "../launchScreenTransition/launchScreenTransition";
import { closestContactsStore } from '../../store/closestContactsStore'

import "./style.css";
import { fetchFirebaseToken, initializeMessaging } from "../../utils/firebase";
import { setFirebaseToken } from "../../utils/safe-storage";
import UnpaidMemberPopupModal from "../unpaidMemberModal/unpaidMemberModal";
import { appStore } from "../../store/appStore";

let searchTitle = "";
let isCalled = false

const NewDashboard = () => {
  const openSubscription = appStore((state) => state.openSubscription)
  const setOpenSubscription = appStore((state) => state.setOpenSubscription)
  const selectedBtn = dashboardStore((state) => state.selectedBtn);
  const setSelectedBtn = dashboardStore((state) => state.setSelectedBtn);
  const removeAll = closestContactsStore((state) => state.removeAll);
  const { user, setUser } = useContext(UserContext);
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [isHighlightSearchMe, setHighlightSearchMe] = useState(true);
  const [showImportContactBanner, setShowImportContactBanner] = useState();
  const [updateUser] = useMutation(UPDATE_FERRET_USER);
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const [showSplash, setShowSplash] = useState(false); //state?.showSplash - code commmented, it's showing again when go back to dashboard.
  const [getUserInfo, { loading, error, data }] = useLazyQuery(ME);
  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState));
  const contactData = location?.state?.contactData ?? {};
  const [unpaidMemberModal, setUnpaidMemberModal] = useState(false);

  useEffect(() => {
    if (state?.showSplash === true) setShowSplash(state?.showSplash);
    else setShowSplash(false);

    if (state?.screen == "Contacts") {
      setSelectedBtn(state?.screen);
      searchTitle = state?.searchTitle;
    }
    else if (window.location.pathname == '/savedSearch') {
      setSelectedBtn('Searches');
      searchTitle = '';
    }

    navigate({ state: { ...state, screen: "", searchTitle: "" } });

    return () => {
      searchTitle = "";
    };
  }, [state]);

  useEffect(() => {
    if (showSplash) {
      setTimeout(() => {
        navigate({ state: { ...state, showSplash: false } })
        setShowSplash(false)
      }, 1000)
    }
  }, [showSplash]);

  const onBoardingStateDashboard = [
    null,
    OnboardingStates.newSearch,
    OnboardingStates.recentActivity,
    OnboardingStates.savedSearches,
    OnboardingStates.completed,
    OnboardingStates.notificationBanner,
    OnboardingStates.notificationBannerEnd,
  ];

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };
  const navigateToDashboard = async () => {
    setSubscriptionModalVisible(false);
    setSearchLimitExceed(false);
  };

  const [updateUserDevice] = useMutation(UPDATE_DEVICE_TOKEN);

  const updateDeviceInfo = async () => {
    const deviceInfo = await getDeviceDetails();
    if (deviceInfo && deviceInfo.deviceToken) {
      updateUserDevice({
        variables: {
          deviceDetails: deviceInfo,
        },
      });
    }
  };

  useEffect(() => {
    
    if (openSubscription) {
      setOpenSubscription(false)
      setSubscriptionModalVisible(true)
    }

    getUserInfo()

    fetchFirebaseToken(setFirebaseToken)
      .then((response) => {
        if (!isCalled) {
          isCalled = true;
          updateDeviceInfo();
          initializeMessaging()
        }
      })
      .catch(error => console.error(error))

    return () => {
      isCalled = false
    }
  }, [])

  useEffect(() => {
    if (!loading && data) {
      setUser(setUserInfo(data.me));
    }
  }, [loading, data]);

  useEffect(() => {
    // window.scrollTo(0,0);
  }, [selectedBtn]);

  useEffect(() => {
    async function fetchData() {
      if (
        (!userInfo.isOnboarded &&
          onboardingState === OnboardingStates.filterHeader) ||
        (!userInfo.notificationOnboardingDone &&
          (onboardingState === OnboardingStates.end ||
            onboardingState === OnboardingStates.filterHeader))
      ) {
        const res = await updateUser({
          variables: {
            isOnboarded: true,
            notificationOnboardingDone: true, //showing notification tile by default - C4R-5655
            // notificationOnboardingDone: Variables.isContactImported, //expected - if contacts imported then notification boarding will be shown already.
          },
        });
        const newUserInfo = {
          ...userInfo,
          isOnboarded: res.data.updateUser.isOnboarded,
          notificationOnboardingDone:
            res.data.updateUser.notificationOnboardingDone,
        };
        let u = setUserInfo(res.data.updateUser);
        setUser({ ...u });
      }
    }
    fetchData();
  }, [onboardingState]);

  useEffect(() => {
    getStarContact();
  }, [selectedBtn]);

  const [getStarContact, { loading: loadingContacts, data: dataContacts }] =
    useLazyQuery(GET_SAVED_SEARCHES, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      // let mItem = [...cardItems].filter(item => item.type != 'onboardingContact' && item.type != 'onboardingStar' && item.type != 'closestContacts' && item.type != 'inTheNews')
      if (dataContacts && dataContacts?.getSavedSearches?.totalContacts) {
        if (
          dataContacts &&
          dataContacts?.getSavedSearches?.contacts &&
          dataContacts?.getSavedSearches?.contacts?.length <
          dataContacts?.getSavedSearches?.totalContacts
        ) {
          setShowImportContactBanner(false);
          Variables.isContactImported = true;
        } else {
          Variables.isContactImported = false;
          setHighlightSearchMe(false);
          setShowImportContactBanner(true);
        }
      } else {
        Variables.isContactImported = false;
        setHighlightSearchMe(false);
        setShowImportContactBanner(true);
        // setCardItems([onBoardingContactItem].concat([inTheNews].concat([closestContacts].concat(mItem))))
      }
    }
  }, [loadingContacts, dataContacts]);

  useEffect(() => {
    if (contactData) {
      if (contactData?.getSavedSearches?.totalContacts) {
        if (
          contactData &&
          contactData?.getSavedSearches?.contacts &&
          contactData?.getSavedSearches?.contacts?.length <
          contactData?.getSavedSearches?.totalContacts
        ) {
          setShowImportContactBanner(false);
          Variables.isContactImported = true;
        } else {
          Variables.isContactImported = false;
          setHighlightSearchMe(false);
          setShowImportContactBanner(true);
        }
      } else {
        Variables.isContactImported = false;
        // setHighlightSearchMe(false)
        // setShowImportContactBanner(true)
        // setCardItems([onBoardingContactItem].concat([inTheNews].concat([closestContacts].concat(mItem))))
      }
    }
  }, []);

  const items = [
    {
      description:
        "Your short list of close contacts, and other saved searches with notifications",
      name: "Watched Contacts",
      type: "closestContacts",
      icon: <WatchedSearchesIcon />,
    },
    {
      description: "Find Recently Viewed Articles",
      name: "Recently Viewed Articles",
      type: "recentActivity",
      icon: <HistoryIcon />,
    },
    {
      title: "Share Ferret",
      description: "Refer a Friend to Ferret…",
      name: "",
      type: "referFriend",
      titleIcon: <DefaultAvatar />,
    },
  ];

  const onPressItem = (type, item) => {
    if (type == "searchMe") {
    } else if (type == "referFriend") {
      //navigation.navigate('InviteContacts')
    } else if (type == "removeShare") {
      // setCardItems((items)=> items.filter(item=> item.type != 'referFriend'))
    } else if (type == "recentActivity") {
      navigate("/recentViewActivity");
    } else if (type == "closestContacts") {
        removeAll();
        navigate("/closestContacts");
    } else if (type == "onboardingContact") {
      // Variables.navigationStackNameForContacts = 'RecentActivity'
      // navigation.navigate('ImportContactsLanding',{screenName: 'newDashboard'})
    } else if (type == "onboardingStar") {
      // Variables.navigationStackNameForContacts = 'newDashboard'
      // navigation.navigate('ClosestContactsEditList',{contactsList: contactList})
    } else if (type == "inTheNews") {
      // navigation.navigate('InTheNews')
    }
  };

  const onOpenNotification = () => {
    navigate("/notification");
  };

  const disableButtonsOnScreen =
    onboardingState == OnboardingStates.recentActivity ||
    onboardingState == OnboardingStates.newSearch ||
    onboardingState == OnboardingStates.savedSearches;

  return (
    <>
      {showSplash ? (
        <LaunchScreenTransition />
      ) : (
        <div className="mainDashboard">
          {selectedBtn == "Contacts" ? (
            <SavedSearchesWithQuery searchTitle={searchTitle} />
          ) : (
            <div>
              <CompleteTooltip />
              <DashboardHeader user={user} navigate={navigate} />
              <div className="borderWatchDash">
                {(user?.isSubscribed !== undefined && user?.isSubscribed == false) ?
                  <div style={{ margin: "10px 3px 0" }}>
                    <DarkCardItem
                      title="Unlock More with Premium"
                      description="Unlock the full power of Ferret with additional searches and monitoring"
                      name="Start 7-day Free Trial"
                      descriptionIcon={
                        <FingerPrintLogo className="fingerprintLogo" />
                      }
                      imgSpanStyle={{ height: "20%", marginTop: -25 }}
                      descriptionTextBoxStyle={{ minHeight: "90px" }}
                      onClick={() => setSubscriptionModalVisible(true)}
                    />
                  </div>
                  :
                  <></>
                }
                {showImportContactBanner != undefined && (
                  <>
                    {" "}
                    {showImportContactBanner && (
                      <div style={{ margin: "16px 3px 0" }}>
                        <ImportContactsBanner />
                      </div>
                    )}
                  </>
                )}
                <div>
                  <NotificationListView
                    onOpenNotification={onOpenNotification}
                  />
                  {onboardingState ===
                    OnboardingStates.notificationBanner && (
                      <NotificationTooltip
                        onClick={() => changeOnboardingState()}
                      />
                    )}
                </div>
                <div className="cards">
                  {items.map((i) => (
                    <CardItem
                      user={user}
                      key={i.name}
                      description={i.description}
                      title={i.title}
                      name={i.name}
                      icon={i.icon}
                      type={i.type}
                      titleIcon={i.titleIcon}
                      onPressItem={onPressItem}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <DashboardFooter
            selectedBtn={selectedBtn}
            setSelectedBtn={setSelectedBtn}
            navigate={navigate}
            setSearchLimitExceed={setSearchLimitExceed}
            disableButtonsOnScreen={disableButtonsOnScreen}
          />
          {isSearchLimitExceed && (
            <SearchLimitExceedModal
              onBackdropPress={() => {
                setSearchLimitExceed(false);
              }}
              startFreeTrial={startFreeTrial}
              goback={navigateToDashboard}
              modelType={ModelType.SEARCH_LIMIT_MODAL}
            />
          )}
          {unpaidMemberModal && (
            <UnpaidMemberPopupModal
              onBackdropPress={() => {
                setUnpaidMemberModal(false);
              }}
              upgradeNow={() => {
                setSubscriptionModalVisible(true);
              }}
            />
          )}
          {subscriptionModalVisible && (
            <SubscriptionPlansModal
              subscriptionModalVisible={subscriptionModalVisible}
              setSubscriptionModalVisible={setSubscriptionModalVisible}
            />
          )}

          {(!onBoardingStateDashboard.includes(onboardingState) ||
            (showImportContactBanner &&
              onboardingState === OnboardingStates.notificationBannerEnd)) &&
            user.neverShowNotifications === false && <PushNotificationStatus />}
        </div>
      )}
    </>
  );
};

export default NewDashboard;
