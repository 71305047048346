import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
let messaging

export const fetchFirebaseToken = async (setFirebaseToken) => {
  const isSupportedBrowser = await isSupported();
  if (isSupportedBrowser) {
    messaging = getMessaging(app);
  }
  if (messaging) {
    return getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          setFirebaseToken(currentToken);
        }
        return currentToken
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  } else {
    return null
  }
};

export const initializeMessaging = async () => {
  const isSupportedBrowser = await isSupported();
  if (isSupportedBrowser) {
    messaging = messaging ?? getMessaging(app);
  }

  if (messaging) {
    onMessage(messaging, (payload) => {
      if (payload && payload?.data?.type === "linkedin-profile-match") {
        const broadcast = new BroadcastChannel('background-linkedin-message');
        broadcast.postMessage(payload);
        return
      }
      
      const broadcast = new BroadcastChannel('refresh-notification');
      broadcast.postMessage(payload);

      (payload?.notification &&
        payload?.data?.type !== "linkedin-profile-match" &&
        payload?.data?.type !== "import-contact-process-notification") &&
        toast(<ToastDisplay payload={payload} />);
    });
  }
}

/**
 * Notificaton from firebase
 * @returns {JSX.Element}
 */
const ToastDisplay = ({ payload }) => {
  const navigate = useNavigate();

  // const gotoArticle = (articleId, contactId, notificationId) => {
  //   getArticleDetails({
  //     variables: {
  //       articleId: articleId,
  //       contactId: contactId
  //     },
  //   }, notificationId, true);
  // }

  const openNotification = () => {
    if (payload?.notification?.type == "Metasearch") {
      navigate("/dashboard", {
        replace: true,
        state: {
          screen: "Contacts",
          searchTitle: payload?.notification?.title,
        },
      });
    } else {
      navigate("/notification");
    }
    toast.dismiss();
    //gotoArticle(payload.data.articleId, payload.data.contactId, payload.data.notificationId)
  };

  return (
    <div onClick={() => openNotification()}>
      {payload?.notification && (
        <>
          <span>{payload.notification.title}</span>
          <p>{payload.notification.body}</p>
        </>
      )}
    </div>
  );
};
